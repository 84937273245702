import styled from '@emotion/styled';
import { BackgroundImage, Flex } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

export const FeaturedOfferWrapper = styled(Flex)`
  flex-direction: column;
  position: relative;
  text-align: left;
`;

export const HeroImage = styled(BackgroundImage)`
  border-radius: ${themeGet('radii.defaultRoundTopOnly')};
  width: 100%;

  ${themeGet('mediaQueries.1')} {
    border-radius: ${themeGet('radii.default')};
    margin-bottom: ${themeGet('space.6')};
  }
`;
