import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { mediaQuery } from 'lib/styledSystem';
import Image from 'components/Image';
import { Text } from '@qga/roo-ui/components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${themeGet('space.4')};

  ${mediaQuery.minWidth.md} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const FullWidthImage = styled(Image)`
  width: 100%;
`;

export const LinkText = styled(Text)`
  color: ${themeGet('colors.brand.primary')};
  font-weight: ${themeGet('fontWeights.bold')};
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;
