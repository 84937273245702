import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Card, Button } from '@qga/roo-ui/components';
import { rem } from 'polished';
import { mediaQuery } from 'lib/styledSystem';

export const FeaturedOfferCard = styled(Card)`
  width: 100%;
  border-radius: ${themeGet('radii.defaultRoundBottomOnly')};
  padding: ${themeGet('space.4')};
  margin-bottom: ${themeGet('space.3')};

  ${mediaQuery.minWidth.sm} {
    padding: ${themeGet('space.6')} ${themeGet('space.20')} ${themeGet('space.10')} ${themeGet('space.20')};
  }

  ${mediaQuery.minWidth.md} {
    position: absolute;
    top: 90px;
    left: 90px;
    max-width: ${rem(490)};
    box-shadow: ${themeGet('shadows.primary')};
    padding: ${themeGet('space.8')} ${themeGet('space.10')} ${themeGet('space.10')} ${themeGet('space.10')};
    border-radius: ${themeGet('radii.default')};
  }
`;

export const Cta = styled(Button)`
  padding: ${themeGet('space.3')} ${themeGet('space.7')};
  line-height: ${themeGet('lineHeights.tight')};
  &:hover {
    color: ${themeGet('colors.white')};
  }
`;
