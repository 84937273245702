import React, { useCallback } from 'react';
import { BackgroundImage, Heading, Text, Box } from '@qga/roo-ui/components';
import { useDataLayer } from 'hooks/useDataLayer';
import { getImage } from 'lib/sanity';
import { OfferWrapper, PromotionalSash, OfferCtaLink } from './Offer.style';
import ImageFallback from 'components/Image/ImageFallback';
import Markdown from 'components/Markdown';
import { Offer as Props } from 'types/content';
import getMarkdownForBlockContent from 'lib/sanity/getMarkdownForBlockContent';
import { getToWithGclid } from 'components/Content/helper';
import { getGclid } from 'components/HomePage/sessionStorage';

const Offer = ({ name, description, callToAction, promotionalSash, image: imageAsset, width, height }: Props) => {
  const { name: alt, asset } = imageAsset || {};
  const image = asset ? getImage(asset.asset, { width: 392, height: 280 }) : null;
  const { emitInteractionEvent } = useDataLayer();
  const newTarget = callToAction?.external ? '_blank' : '';
  const newRel = callToAction?.external ? 'noopener noreferrer' : '';
  const gclid = getGclid();
  const appendGclid = gclid && !callToAction?.external;
  const newTo = appendGclid ? getToWithGclid(callToAction?.url, gclid) : callToAction?.url;

  const handleCallToActionClick = useCallback(() => {
    emitInteractionEvent({
      type: 'Offer Link',
      value: `${name} Selected`,
    });
  }, [emitInteractionEvent, name]);

  return (
    <OfferWrapper width={['100%', width]}>
      <Box position="relative" borderRadius="default" overflow="hidden">
        {promotionalSash && (
          <Box position="absolute" left={0} top={0}>
            <PromotionalSash>{promotionalSash}</PromotionalSash>
          </Box>
        )}
        {image && <BackgroundImage src={image?.custom ?? ''} alt={alt || 'An image'} height={['250px', '160px', height]} width="100%" />}
        {!image && <ImageFallback alt="Region image placeholder" height={['250px', height]} width="100%" />}
      </Box>
      <Heading.h3 display="block" fontSize={['lg', 'md', '22px']} mt={[4, 6]} mb={2} color="greys.charcoal">
        {name}
      </Heading.h3>
      <Text fontSize="base" color="greys.steel">
        <Markdown content={getMarkdownForBlockContent(description)} />
      </Text>
      {callToAction && (
        <OfferCtaLink data-testid="offer-link" to={newTo} href={null} rel={newRel} target={newTarget} onClick={handleCallToActionClick}>
          {callToAction?.text}
        </OfferCtaLink>
      )}
    </OfferWrapper>
  );
};

export default Offer;
