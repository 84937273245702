import React from 'react';
import { getImage } from 'lib/sanity';
import { FeaturedOffer as FeaturedOfferProps } from 'types/content';
import TermsAndConditions from './TermsAndConditions';
import FeaturedOfferBanner from './FeaturedCampaignBanner';
import { FeaturedOfferWrapper, HeroImage } from './FeaturedCampaign.style';

const FeaturedCampaign = ({
  backgroundImage,
  callToAction,
  name,
  offerDescription,
  promotionalSash,
  terms,
  termsShort,
}: FeaturedOfferProps) => {
  const backgroundImageSrc = getImage(backgroundImage?.asset);

  return (
    <FeaturedOfferWrapper mx={[3, 0]}>
      <HeroImage
        src={backgroundImageSrc?.medium ?? ''}
        alt={backgroundImage?.name}
        role="img"
        height={['234px', '290px', '584px']}
        aria-label="Campaign image"
      />
      <FeaturedOfferBanner heading={name} description={offerDescription} promotionalSash={promotionalSash} callToAction={callToAction} />
      <TermsAndConditions terms={terms} termsShort={termsShort} />
    </FeaturedOfferWrapper>
  );
};

export default FeaturedCampaign;
