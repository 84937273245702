import React, { FC, useMemo } from 'react';
import { Flex, Text } from '@qga/roo-ui/components';
import PageBlock from 'components/PageBlock';
import getGroupedTerms from './utils/getGroupedTerms';
import { CONTENT_TERMS_ANCHOR_ID } from './index';
import { TermsDisclaimerItem, DisclaimerTitle } from './TermsAndConditions.style';

interface Props {
  termsAndConditions?: Array<object>;
}

const TermsAndConditions: FC<Props> = ({ termsAndConditions = [] }: Props) => {
  const groupedTerms = useMemo(() => getGroupedTerms(termsAndConditions), [termsAndConditions]);

  return groupedTerms?.length > 0 ? (
    <PageBlock id={CONTENT_TERMS_ANCHOR_ID} py={10} bg="white">
      <Text display="block" fontSize="base" fontWeight="bold" mb={3} color="greys.charcoal">
        Terms and Conditions
      </Text>

      {groupedTerms.map((item, index) => (
        <Flex flexDirection="column" key={`offer-terms-group-${item.title}-${index}`}>
          {item?.title && (
            <DisclaimerTitle>
              <strong>{item.title}</strong>
            </DisclaimerTitle>
          )}

          {item.disclaimers.map(
            (disclaimer, index) => disclaimer && <TermsDisclaimerItem key={`offer-terms-disclaimer-${index}`} disclaimer={disclaimer} />,
          )}
        </Flex>
      ))}
    </PageBlock>
  ) : null;
};

export default TermsAndConditions;
