import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Link, Text } from '@qga/roo-ui/components';

export const DisclaimerText = styled(Text)`
  display: block;
  color: ${themeGet('colors.greys.steel')};
  font-size: ${themeGet('fontSizes.xs')};
  margin-bottom: ${themeGet('space.1')};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const InlineLink = styled(Link)`
  display: inline;
  text-decoration: underline;
  color: ${themeGet('colors.greys.steel')};
  font-size: ${themeGet('fontSizes.xs')};
`;
