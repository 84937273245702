import styled from '@emotion/styled';
import { Box, Flex } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { mediaQuery } from 'lib/styledSystem';
import AppLink from 'components/AppLink';

export const OfferWrapper = styled(Flex)`
  flex-direction: column;
  margin-bottom: ${themeGet('space.6')};
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
    flex-grow: 1;
  }

  ${mediaQuery.minWidth.sm} {
    margin-bottom: 0;
    margin-right: ${themeGet('space.4')};

    &:last-of-type {
      margin-right: 0;
      flex-grow: 1;
    }
  }

  ${mediaQuery.minWidth.md} {
    margin-right: ${themeGet('space.6')};

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const PromotionalSash = styled(Box)`
  background-color: ${themeGet('colors.brand.secondary')};
  padding: ${themeGet('space.1')} ${themeGet('space.3')};
  border-bottom-right-radius ${themeGet('radii.exaggerated')};
  font-size: ${themeGet('fontSizes.xs')};
  font-weight: ${themeGet('fontWeights.bold')};
  text-transform: uppercase;
`;

export const OfferCtaLink = styled(AppLink)`
  font-weight: ${themeGet('fontWeights.bold')};
`;
