import React from 'react';
import { Text } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import TermsMarkdown from './TermsMarkdown';
import getMarkdownForBlockContent from 'lib/sanity/getMarkdownForBlockContent';

export const DisclaimerTitle = styled(Text)`
  color: ${themeGet('colors.greys.charcoal')};
  font-size: ${themeGet('fontSizes.sm')};
  margin: ${themeGet('space.2')} 0;
  font-weight: ${themeGet('fontWeights.bold')};
`;

interface Disclaimer {
  content?: Array<object>;
}

interface Props {
  disclaimer?: Disclaimer;
}

export const TermsDisclaimerItem = ({ disclaimer = { content: [] } }: Props) => {
  const mdContent = getMarkdownForBlockContent(disclaimer?.content || []);
  return <TermsMarkdown content={mdContent} />;
};
