import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Tooltip } from '@qga/roo-ui/components';
import { getPayWithToggleMessage } from 'store/campaign/campaignSelectors';
import { getIsExclusive } from 'store/property/propertySelectors';

const PayWithToggleMessage = ({ pointerDirection, pointerPosition, ...rest }) => {
  const payWithToggleMessage = useSelector(getPayWithToggleMessage);
  const isExclusive = useSelector(getIsExclusive);

  return !isExclusive && payWithToggleMessage ? (
    <Tooltip {...rest} pointerDirection={pointerDirection} pointerPosition={pointerPosition} width="fit-content">
      {payWithToggleMessage}
    </Tooltip>
  ) : null;
};

// inherited the pointinDirection prop from polished triangle helper. Not the most intuitive values but consistent at least
PayWithToggleMessage.propTypes = {
  pointerDirection: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
  pointerPosition: PropTypes.string,
};

PayWithToggleMessage.defaultProps = {
  pointerPosition: '50%',
};

export default PayWithToggleMessage;
