import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import Markdown from 'components/Markdown';
import { NakedButton, Text, Box } from '@qga/roo-ui/components';
import Modal from 'components/Modal';
import { useModal } from 'lib/hooks';
import { useDataLayer } from 'hooks/useDataLayer';
import { mediaQuery } from 'lib/styledSystem';

interface TermsAndConditionsProps {
  terms?: string;
  termsShort?: string;
}

const LinkButton = styled(NakedButton)`
  text-decoration: underline;
  margin-left: ${themeGet('space.1')};
  color: ${themeGet('colors.greys.steel')};
  font-size: ${themeGet('fontSizes.xs')};

  ${mediaQuery.minWidth.sm} {
    font-size: ${themeGet('fontSizes.base')};
  }
`;

const TermsAndConditions = ({ termsShort, terms }: TermsAndConditionsProps) => {
  const { emitInteractionEvent } = useDataLayer();
  const { openModal, modalProps } = useModal();

  const handleOpenModal = () => {
    openModal();
    emitInteractionEvent({
      type: 'Featured Offer Carousel',
      value: 'Terms and Conditions Clicked',
    });
  };

  return (
    <>
      <Box>
        {termsShort && (
          <Text color="greys.steel" fontSize={['xs', 'base']} data-testid="disclaimer-copy">
            {termsShort}
          </Text>
        )}
        {terms && (
          <LinkButton onClick={handleOpenModal} data-testid="terms-and-conditions-button">
            Conditions apply.
          </LinkButton>
        )}
      </Box>
      {terms && (
        <Modal {...modalProps} fullScreen={false} padding={8} title="Offer Terms and Conditions" footerComponent={null}>
          <Markdown content={terms} />
        </Modal>
      )}
    </>
  );
};

export default TermsAndConditions;
