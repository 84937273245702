import React, { FC, useCallback, useMemo } from 'react';
import { Flex, Heading, RegionLinks } from '@qga/roo-ui/components';
import { LinkList } from './types';
import { useDataLayer } from 'hooks/useDataLayer';
import AppLink from 'components/AppLink';
import { getGclid } from 'components/HomePage/sessionStorage';

export interface PopularDestinationFooterProps {
  links: LinkList[];
}

const PopularDestinationFooter: FC<PopularDestinationFooterProps> = ({ links }: PopularDestinationFooterProps) => {
  const { emitInteractionEvent } = useDataLayer();
  const gclid = getGclid();

  const handleDestinationClick = useCallback(
    ({ name: trackingName }) => {
      emitInteractionEvent({ type: 'Destination Footer', value: `${trackingName} Selected` });
    },
    [emitInteractionEvent],
  );

  const getTo = useCallback(
    (to) => {
      if (gclid) {
        const baseUrl = new URL(to, 'https://dummy.com');
        const params = new URLSearchParams(baseUrl.search);
        params.append('gclid', gclid);
        return baseUrl.pathname + '?' + decodeURIComponent(params.toString());
      } else {
        return to;
      }
    },
    [gclid],
  );

  const regionLinks = useMemo(
    () =>
      links.map((link) => ({
        title: link.title,
        regions:
          link.linkList?.map((linkItem) => ({
            href: undefined as unknown as string,
            to: getTo(linkItem.to),
            name: String(linkItem.children),
            as: AppLink,
          })) ?? [],
        onClick: handleDestinationClick,
      })),
    [links, handleDestinationClick, getTo],
  );

  return (
    <Flex flexDirection="column" data-testid="popular-destination-footer">
      <Heading.h2
        textAlign="center"
        mb={6}
        color="greys.charcoal"
        fontWeight="normal"
        fontSize="lg"
        data-testid="popular-destination-header"
      >
        Find Hotels in popular destinations
      </Heading.h2>
      <Flex flexWrap="wrap">
        {regionLinks.map((link, index) => (
          <RegionLinks key={`footer-list-${index}`} colorMode="dark" {...link} />
        ))}
      </Flex>
    </Flex>
  );
};

export default PopularDestinationFooter;
