import styled from '@emotion/styled';
import { Box, NakedButton, Icon } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { rem } from 'polished';
import { mediaQuery } from 'lib/styledSystem';

export const CarouselWrapper = styled(Box)`
  text-align: center;
  position: relative;
`;

export const Scroller = styled.ul`
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;

  list-style-type: none;
  padding: 0;
  margin: 0;

  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollItem = styled.li<{ inert?: string | null }>`
  min-width: 100%;
  scroll-snap-align: center;
`;

const NavigationButton = styled(NakedButton)`
  width: ${rem('32px')};
  height: ${rem('32px')};
  border-radius: ${themeGet('radii.rounded')};
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: calc((234px / 2) - 16px);

  ${mediaQuery.minWidth.sm} {
    top: calc((290px / 2) - 16px);
  }

  ${mediaQuery.minWidth.md} {
    top: calc((584px / 2) - 16px);
  }
`;

export const PreviousNavigationButton = styled(NavigationButton)`
  left: ${themeGet('space.4')};
`;

export const NextNavigationButton = styled(NavigationButton)`
  right: ${themeGet('space.4')};
`;

export const BreadcrumbButton = styled(NakedButton)`
  width: ${rem('8px')};
  height: ${rem('8px')};
  border-radius: ${themeGet('radii.rounded')};
  background-color: ${themeGet('colors.greys.charcoal')};
  margin-right: ${themeGet('space.3')};
  opacity: ${(props) => (props.selected ? '1' : '0.3')};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const ArrowIcon = styled(Icon)`
  vertical-align: initial;
`;
