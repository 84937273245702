import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { getImage } from 'lib/sanity';
import { Box, Text, Flex, Heading, Image } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { mediaQuery } from 'lib/styledSystem';

const ValuePropositionStandardWrapper = styled(Flex)`
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${themeGet('space.4')};
  max-width: 500px;
  ${mediaQuery.minWidth.sm} {
    flex-direction: column;
    max-width: 350px;
    padding: ${themeGet('space.11')};
  }
`;

const ValuePropositionSlimlineWrapper = styled(Flex)`
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${themeGet('space.4')};
  max-width: 500px;
`;

const ValuePropositionStandard = ({ title, iconName, description }) => {
  const { asset, caption } = iconName;
  const image = getImage(asset);

  return (
    <ValuePropositionStandardWrapper
      data-testid="standard-layout"
      width={[1, 1, 1 / 4, 1 / 4]}
      mb={[1, 2]}
      alignItems={['flex-start', 'center']}
    >
      <Image alt={caption} display="block" src={image.small} height={50} mb={2} mt={[4, 0]} mr={[6, 6, 0, 0]} width="auto" />
      <Flex flexDirection={['column', 'column', 'row']} flexWrap="wrap" width="100%" textAlign={['left', 'center']}>
        <Heading display="block" fontSize="sm" mb={0} mx={[0, 'auto', 'auto']} mt={1}>
          {title}
        </Heading>
        <Text display="block" fontSize={14} mt={1}>
          {description}
        </Text>
      </Flex>
    </ValuePropositionStandardWrapper>
  );
};

ValuePropositionStandard.propTypes = {
  title: PropTypes.string.isRequired,
  iconName: PropTypes.object,
  description: PropTypes.string,
};

ValuePropositionStandard.defaultProps = {
  iconName: null,
  description: '',
};

const ValuePropositionSlimline = ({ title, iconName }) => {
  const { asset, caption } = iconName;
  const image = getImage(asset);

  return (
    <ValuePropositionSlimlineWrapper data-testid="slimline-layout">
      <Image alt={caption} display="block" src={image.small} height={50} mr={2} width={50} />
      <Heading display="block" fontSize="sm" mt={[4, 4, 3, 3]} mx={2}>
        {title}
      </Heading>
    </ValuePropositionSlimlineWrapper>
  );
};

ValuePropositionSlimline.propTypes = {
  title: PropTypes.string.isRequired,
  iconName: PropTypes.object,
};

ValuePropositionSlimline.defaultProps = {
  iconName: null,
};

const ValuePropositionsList = ({ title, valuePropositions, layout }) => (
  <Box ml={valuePropositions.length < 4 ? ['auto', 5, 30, 30] : 0}>
    {layout === 'standard' && (
      <Heading.h1 mt={10} mx="auto" mb={2} fontSize="lg">
        {title}
      </Heading.h1>
    )}
    <Flex flexDirection={['column', 'row']} flexWrap="space-between" justifyContent="center" mx="auto" data-testid="elements-wrapper">
      {layout === 'standard' &&
        valuePropositions.map((item, i) => (
          <ValuePropositionStandard key={i} title={item.title} iconName={item.iconName} description={item.description} />
        ))}
      {layout === 'slimline' &&
        valuePropositions.map((item, i) => <ValuePropositionSlimline key={i} title={item.title} iconName={item.iconName} />)}
    </Flex>
  </Box>
);

ValuePropositionsList.propTypes = {
  title: PropTypes.string.isRequired,
  valuePropositions: PropTypes.array.isRequired,
  layout: PropTypes.string,
};

ValuePropositionsList.defaultProps = {
  layout: 'standard',
};

export default ValuePropositionsList;
