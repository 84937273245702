import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Box, Hide } from '@qga/roo-ui/components';
import {
  CarouselWrapper,
  Scroller,
  ScrollItem,
  PreviousNavigationButton,
  NextNavigationButton,
  BreadcrumbButton,
  ArrowIcon,
} from './Carousel.style';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import Visibility from 'lib/browser/visibility';
import { useDataLayer } from 'hooks/useDataLayer';
import SkipToContentButton from 'components/SkipToContentButton';

interface Props {
  width?: string;
  itemList: Array<object>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemRenderer?: React.FC<any>;
  skipToContent?: string;
}

const POLLING_INTERVAL = 20000; // 20 seconds

const Carousel = ({ width = '100%', itemList = [], itemRenderer: ItemRenderer, skipToContent }: Props) => {
  const { emitInteractionEvent } = useDataLayer();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollerRef = useRef<HTMLUListElement>(null);
  const pollerRef = useRef<number>();
  const enableScrolling = itemList.length > 1;

  const decrementCarousel = useCallback(() => {
    if (scrollerRef?.current) {
      const scroller = scrollerRef.current;
      if (scroller.scrollLeft !== 0 && scroller?.scrollBy) {
        scroller.scrollBy({ left: -scroller.clientWidth, top: 0, behavior: 'smooth' });
      } else if (scroller?.scrollTo) {
        // Scroll to end
        scroller.scrollTo({ left: scroller.scrollWidth, top: 0, behavior: 'smooth' });
      }
    }
  }, [scrollerRef]);

  const incrementCarousel = useCallback(() => {
    if (scrollerRef?.current) {
      const scroller = scrollerRef.current;
      if (scroller.scrollLeft < scroller.scrollWidth - scroller.clientWidth && scroller?.scrollBy) {
        scroller.scrollBy({ left: scroller.clientWidth, top: 0, behavior: 'smooth' });
      } else if (scroller?.scrollTo) {
        // Scroll to start
        scroller.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      }
    }
  }, [scrollerRef]);

  const scrollTo = useCallback(
    (scrollToIndex) => {
      if (scrollerRef?.current && typeof scrollerRef.current?.scrollTo === 'function') {
        const scroller = scrollerRef.current;
        scroller.scrollTo({ left: scrollToIndex * scroller.clientWidth, top: 0, behavior: 'smooth' });
      }
    },
    [scrollerRef],
  );

  const previousClick = useCallback(() => {
    decrementCarousel();

    emitInteractionEvent({
      type: 'Featured Offer Carousel',
      value: 'Previous Card Selected (using arrow)',
    });
  }, [decrementCarousel, emitInteractionEvent]);

  const nextClick = useCallback(() => {
    incrementCarousel();

    emitInteractionEvent({
      type: 'Featured Offer Carousel',
      value: 'Next Card Selected (using arrow)',
    });
  }, [incrementCarousel, emitInteractionEvent]);

  const onScrollerUpdated = debounce((event) => {
    const scroller = event.target;
    const index = Math.round((scroller.scrollLeft / scroller.scrollWidth) * itemList.length);
    setSelectedIndex(index);

    // Reset the auto-scroll timer when the user scrolls the carousel
    if (typeof pollerRef?.current === 'number') Visibility.stop(pollerRef.current);
    pollerRef.current = Visibility.every(POLLING_INTERVAL, incrementCarousel);
  }, 100);

  const breadcrumbClicked = useCallback(
    (index) => {
      scrollTo(index);

      emitInteractionEvent({
        type: 'Featured Offer Carousel',
        value: `Card ${index + 1} selected`,
      });
    },
    [scrollTo, emitInteractionEvent],
  );

  useEffect(() => {
    if (enableScrolling) {
      // Start the auto scrolling on mount
      pollerRef.current = Visibility.every(POLLING_INTERVAL, incrementCarousel);
      return () => {
        if (typeof pollerRef?.current === 'number') Visibility.stop(pollerRef.current);
      };
    } else {
      if (typeof pollerRef?.current === 'number') Visibility.stop(pollerRef.current);
    }
  }, [enableScrolling, pollerRef, incrementCarousel]);

  return (
    <CarouselWrapper maxWidth={width}>
      {skipToContent && (
        <SkipToContentButton as="a" href={skipToContent}>
          Skip to content
        </SkipToContentButton>
      )}

      <Scroller ref={scrollerRef} onScroll={enableScrolling ? onScrollerUpdated : noop}>
        {itemList.map((props, index) => (
          <ScrollItem
            key={`carousel-item-${index}`}
            data-testid="carousel-item"
            aria-hidden={index !== selectedIndex}
            inert={index !== selectedIndex ? '' : null}
          >
            {!!ItemRenderer && <ItemRenderer {...props} />}
          </ScrollItem>
        ))}
      </Scroller>

      {enableScrolling && (
        <>
          <Hide xs sm>
            <PreviousNavigationButton onClick={previousClick} aria-label="View previous item" data-testid="prev-carousel-btn">
              <ArrowIcon name="chevronLeft" size={12} color="greys.charcoal" />
            </PreviousNavigationButton>
            <NextNavigationButton onClick={nextClick} aria-label="View next item" data-testid="next-carousel-btn">
              <ArrowIcon name="chevronRight" size={12} color="greys.charcoal" />
            </NextNavigationButton>
          </Hide>
          <Box>
            {itemList.map((_, index) => (
              <BreadcrumbButton
                key={`nav-button-${index}`}
                data-testid="breadcrumb-carousel-btn"
                selected={index === selectedIndex}
                onClick={() => breadcrumbClicked(index)}
                aria-label={`View item number ${index}`}
              />
            ))}
          </Box>
        </>
      )}
    </CarouselWrapper>
  );
};

export default Carousel;
