import React, { FC, useCallback, useMemo } from 'react';
import AppLink from 'components/AppLink';
import { useDataLayer } from 'hooks/useDataLayer';
import { Box, Flex, Heading, Icon, RegionCard } from '@qga/roo-ui/components';
import { Link, Region as RegionProps } from 'types/content';
import { Grid, LinkText } from './ListOfRegions.style';
import { getImage } from 'lib/sanity';
import { getGclid } from 'components/HomePage/sessionStorage';
import { addGclidToUrl, getLinkUrl } from './helpers';

interface Props {
  callToAction?: Link;
  links: RegionProps[];
  title: string;
}

const ListOfRegions: FC<Props> = ({ links = [], title, callToAction }: Props) => {
  const { emitInteractionEvent } = useDataLayer();
  const gclid = getGclid();
  const addGclidParam = gclid && !callToAction?.external;
  const callToActionUrl = addGclidParam ? addGclidToUrl(callToAction?.url, gclid) : callToAction?.url;

  const handleCallToActionClick = useCallback(() => {
    emitInteractionEvent({
      type: 'Call To Action',
      value: 'See All Destinations Selected',
    });
  }, [emitInteractionEvent]);

  const handleRegionClick = useCallback(
    (fullName) => {
      emitInteractionEvent({
        type: 'Region Link',
        value: `${fullName} Selected`,
      });
    },
    [emitInteractionEvent],
  );

  const regionLinks = useMemo(
    () =>
      links.map((link) => {
        const [regionName, ...crumbs] = link.fullName.split(', ');
        const image = getImage(link?.image?.asset?.asset, { width: 340, height: 190 });
        return {
          name: `${regionName} Hotels`,
          location: crumbs.join(', '),
          to: getLinkUrl(link, gclid),
          onClick: () => handleRegionClick(link.fullName),
          image: { src: image?.custom ?? '', alt: link?.image?.name ?? '' },
          as: AppLink,
        };
      }),
    [links, gclid, handleRegionClick],
  );

  return (
    <Flex flexDirection="column">
      <Heading.h2
        data-testid="list-regions-title"
        display="block"
        color="greys.charcoal"
        fontSize="lg"
        mb={6}
        fontWeight="normal"
        textAlign="center"
      >
        {title}
      </Heading.h2>
      <Grid>{regionLinks?.map((link, index) => <RegionCard key={`region-${index}`} {...link} />)}</Grid>
      <Box alignSelf="center" pt={6}>
        {callToAction && (
          <AppLink href={null} onClick={handleCallToActionClick} target={null} to={callToActionUrl} data-testid="list-regions-cta">
            <LinkText>{callToAction.text}</LinkText>
            <Icon name="arrowForward" ml={2} size={18} color="brand.primary" />
          </AppLink>
        )}
      </Box>
    </Flex>
  );
};

export default ListOfRegions;
