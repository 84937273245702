import React from 'react';
import { Flex, Heading, Box } from '@qga/roo-ui/components';
import { Offer as OfferProps } from 'types/content';
import Offer from './Offer';
interface Props {
  title?: string;
  offers?: OfferProps[];
}

const OffersList = ({ title, offers = [] }: Props) => (
  <Flex flexDirection="column">
    <Heading.h2 data-testid="offers-list-title" color="greys.charcoal" fontSize="lg" mb={6} textAlign="center" fontWeight="normal">
      {title}
    </Heading.h2>
    {offers.length === 3 && (
      <Box>
        <Flex flexDirection={['column', 'row']}>
          {offers.map((offer, index) => (
            <Offer key={`offer-${index}`} {...offer} width="33%" height="280px" />
          ))}
        </Flex>
      </Box>
    )}
    {offers.length === 5 && (
      <Box>
        <Flex flexDirection={['column', 'row']} pb={12}>
          {offers.slice(0, 2).map((offer, index) => (
            <Offer key={`offer-${index}`} {...offer} width="50%" height="445px" />
          ))}
        </Flex>
        <Flex flexDirection={['column', 'row']}>
          {offers.slice(2, 5).map((offer, index) => (
            <Offer key={`offer-${index}`} {...offer} width="33%" height="280px" />
          ))}
        </Flex>
      </Box>
    )}
  </Flex>
);

export default OffersList;
