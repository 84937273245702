import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@qga/roo-ui/components';
import isEmpty from 'lodash/isEmpty';
import {
  getHomePageContent,
  getFooterLinksByCategory,
  getFeaturedCampaigns,
  getTermsAndConditions,
} from 'store/homePage/homePageSelectors';
import HomePageHelmet from './HomePageHelmet';
import HomePageSearchPanel from './HomePageSearchPanel';
import ListOfRegions from 'components/Content/ListOfRegions';
import RequestCallbackModal from 'components/RequestCallbackModal';
import ValuePropositionsList from 'components/Content/ValuePropositionsList';
import CrossSellList from 'components/Content/CrossSellList';
import OffersList from 'components/Content/OffersList';
import PopularDestinationFooter from 'components/PopularDestinationFooter';
import FeaturedCampaign from './FeaturedCampaign';
import FeaturedCampaignsCarousel from 'components/Content/Carousel';
import TermsAndConditions from 'components/Content/TermsAndConditions';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import CampaignMessaging from 'components/CampaignMessaging';

const HomePageLayout: FC = () => {
  const { valuePropositions = [], regionLinks = [], offers = {}, crossSells = {} } = useSelector(getHomePageContent) || {};
  const footerLinks = useSelector(getFooterLinksByCategory);
  const featuredCampaigns = useSelector(getFeaturedCampaigns);
  const termsAndConditions = useSelector(getTermsAndConditions);
  const isMobileApp = useSelector(getIsMobileApp);
  const hasTerms = !isEmpty(termsAndConditions);

  return (
    <>
      <HomePageHelmet />
      <CampaignMessaging showDefaultMessage={true} />
      <HomePageSearchPanel />

      <RequestCallbackModal interactionType="Homepage search" />

      <div id="main-content"></div>
      {!isMobileApp && (
        <>
          <Container px={0} my={6}>
            <ValuePropositionsList title="" layout="slimline" valuePropositions={valuePropositions} />
          </Container>

          <Container mb={[6, 10, 10]} px={[0, 3]}>
            <FeaturedCampaignsCarousel itemRenderer={FeaturedCampaign} itemList={featuredCampaigns} skipToContent="#offers-list-content" />
          </Container>

          <div id="offers-list-content"></div>

          <Container mb={[6, 12, 12]}>
            <OffersList {...offers} />
          </Container>

          <Container mb={[6, 12, 12]}>
            <CrossSellList {...crossSells} />
          </Container>

          <Container mb={[6, 10, 10]}>
            <ListOfRegions {...regionLinks} title="Hotels & Accommodation in popular destinations" />
          </Container>

          <Container>
            <PopularDestinationFooter links={footerLinks} />
          </Container>

          {hasTerms && <TermsAndConditions termsAndConditions={termsAndConditions} />}
        </>
      )}
    </>
  );
};

export default HomePageLayout;
