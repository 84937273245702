import { Flex, Button } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { FieldError } from '@qga/components';
import { rem } from 'polished';
import { mediaQuery } from 'lib/styledSystem';

export const InputErrorField = styled(FieldError)`
  margin-top: ${themeGet('space.2')};
`;

export const PayWithWrapper = styled(Flex)`
  align-items: flex-end;
  flex-grow: 1;
  align-items: center;
  padding-right: 0;
  margin-top: ${themeGet('space.4')};

  ${mediaQuery.minWidth.sm} {
    margin-top: ${themeGet('space.6')};
    padding-right: ${rem('52px')};
  }

  ${mediaQuery.minWidth.md} {
    margin-top: ${themeGet('space.6')};
    padding-right: 0;
  }
`;

export const SubmitButton = styled(Button)`
  &:hover {
    color: ${themeGet('colors.white')};
  }
`;

export const SubmitButtonFlex = styled(Flex)`
  order: 7;
  margin-top: ${themeGet('space.4')};
  width: 100%;
  justify-content: center;
  ${mediaQuery.minWidth.sm} {
    width: 40%;
    margin-top: ${themeGet('space.6')};
    justify-content: flex-start;
  }
  ${mediaQuery.minWidth.md} {
    width: 100%;
    margin-top: ${themeGet('space.6')};
    justify-content: flex-end;
  }
`;

export const SubmitButtonBox = styled(Flex)`
  order: 7;
  width: 100%;
  align-self: self-start;
  margin-top: ${themeGet('space.3')};

  ${mediaQuery.minWidth.sm} {
    width: 40%;
    margin-top: ${(props) => (props.payWithToggleMessage ? themeGet('space.20') : themeGet('space.6'))};
  }

  ${mediaQuery.minWidth.md} {
    width: 25%;
    margin-top: ${themeGet('space.6')};
  }
`;
