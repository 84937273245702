import React, { FC } from 'react';
import MarkdownToJsx from 'markdown-to-jsx';
import startCase from 'lodash/startCase';
import { useDataLayer } from 'hooks/useDataLayer';
import { HOTELS_HOST, HOTELS_PATH } from 'config';
import { DisclaimerText, InlineLink } from './TermsMarkdown.style';

const hotelsPropertyRegExp = new RegExp(String.raw`^${HOTELS_HOST}${HOTELS_PATH}/properties/(?:[0-9]*)?`);

interface LinkProps {
  href?: string;
  children?: string;
}

const LinkWithTracking: FC<LinkProps> = ({ children, href = undefined, ...props }: LinkProps) => {
  const { emitInteractionEvent } = useDataLayer();

  const handleClick = () => {
    const isHotelsPropertyLink = typeof href === 'string' && hotelsPropertyRegExp.test(href);
    if (children) {
      emitInteractionEvent({
        type: isHotelsPropertyLink ? 'Hotel Text Link' : 'Terms and Conditions Link',
        value: `${startCase(children)} Selected`,
        customAttributes: {
          href,
        },
      });
    }
  };

  return (
    <InlineLink onClick={handleClick} href={href} {...props} rel="noopener noreferrer" target="_blank">
      {children}
    </InlineLink>
  );
};

const markdownOptions = {
  overrides: {
    a: LinkWithTracking,
    p: DisclaimerText,
    span: DisclaimerText,
  },
};

interface TermsProps {
  content?: string;
}

const TermsMarkdown: FC<TermsProps> = ({ content = '', ...rest }: TermsProps) => {
  return (
    <DisclaimerText>
      <MarkdownToJsx options={markdownOptions} {...rest}>
        {content}
      </MarkdownToJsx>
    </DisclaimerText>
  );
};

export default TermsMarkdown;
