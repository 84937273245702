import styled from '@emotion/styled';
import { Link } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { mediaQuery } from 'lib/styledSystem';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${themeGet('space.2')};

  ${mediaQuery.minWidth.sm} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mediaQuery.minWidth.md} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const ValuePropositionWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 0;
  border-radius: ${themeGet('radii.default')};
  border: ${themeGet('borders.2')};
  border-color: ${themeGet('colors.greys.alto')};
  padding: ${themeGet('space.5')} ${themeGet('space.4')};
  background-color: ${themeGet('colors.white')};

  &:hover {
    box-shadow: ${themeGet('shadows.soft')};
  }

  ${mediaQuery.minWidth.sm} {
    flex-direction: column;
    text-align: center;
    padding: ${themeGet('space.5')} ${themeGet('space.3')};
  }
`;
