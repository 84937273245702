import React, { useEffect } from 'react';
import { Box, Badge, Heading, Paragraph } from '@qga/roo-ui/components';
import Markdown from 'components/Markdown';
import AppLink from 'components/AppLink';
import getMarkdownForBlockContent from 'lib/sanity/getMarkdownForBlockContent';
import { FeaturedOfferCard, Cta } from './FeaturedCampaignBanner.style';
import { FeaturedOfferBanner as FeaturedOfferBannerProps } from 'types/content';
import { useDataLayer } from 'hooks/useDataLayer';
import { getGclid } from 'components/HomePage/sessionStorage';

const FeaturedOfferBanner = ({ heading, description, promotionalSash, callToAction }: FeaturedOfferBannerProps) => {
  const { emitInteractionEvent } = useDataLayer();
  const gclid = getGclid();

  const handleCtaClick = () => {
    emitInteractionEvent({
      type: 'Featured Offer Carousel',
      value: 'Call To Action Selected',
    });
  };

  useEffect(() => {
    if (gclid && callToAction?.to) {
      callToAction.to = `${callToAction.to}?gclid=${gclid}`;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FeaturedOfferCard>
      {promotionalSash && <Badge text={promotionalSash} mb="18px" fontWeight="bold" data-testid="promotional-sash" />}
      {heading && (
        <Heading.h2 fontWeight="normal" fontSize="28px" mb="18px">
          {heading}
        </Heading.h2>
      )}
      {description && (
        <Box data-testid="offer-description" mb="18px">
          <Markdown
            content={getMarkdownForBlockContent(description)}
            customComponents={{
              span: { component: Paragraph, props: { color: 'alto', mr: 0, fontSize: 'base' } },
            }}
          />
        </Box>
      )}
      {callToAction && (
        <Cta
          as={AppLink}
          {...callToAction}
          onClick={handleCtaClick}
          width={['100%', '284px', 'initial']}
          target={callToAction?.target ? '_blank' : ''}
          data-testid="carousel-offer-cta"
        >
          {callToAction?.text}
        </Cta>
      )}
    </FeaturedOfferCard>
  );
};

export default FeaturedOfferBanner;
