import React, { FC } from 'react';
import { getImage } from 'lib/sanity';
import { Text, Flex, Heading, Image } from '@qga/roo-ui/components';
import ImageFallback from 'components/Image/ImageFallback';
import type { ValueProposition as ValuePropositionProps } from '@qga/sanity-components/dist/types';
import { Grid, ValuePropositionWrapper } from './CrossSellList.style';
import { useDataLayer } from 'hooks/useDataLayer';

interface ValuePropositionWithLinkProps extends ValuePropositionProps {
  href?: string;
}

const ValueProposition: FC<ValuePropositionWithLinkProps> = ({ title, iconName, description, href }: ValuePropositionWithLinkProps) => {
  const { emitInteractionEvent } = useDataLayer();
  const image = getImage(iconName?.asset);

  const handleOnClick = () => {
    emitInteractionEvent({ type: 'Cross Sell Link', value: `${title} Selected` });
  };

  return (
    <ValuePropositionWrapper href={href} aria-label={title} data-testid="cross-sell-tile" onClick={handleOnClick}>
      {image && <Image alt={iconName?.caption ?? ''} src={image.small ?? ''} height={48} width={48} />}
      {!image && <ImageFallback alt="Region image placeholder" height="48px" width="48px" />}
      <Flex flexDirection="column" ml={[5, 0]}>
        <Heading.h3 fontSize="md" my={2} color="greys.charcoal" textAlign={['left', 'center']} data-testid="cross-sell-tile-title">
          {title}
        </Heading.h3>
        <Text fontSize="sm" color="greys.steel" textAlign={['left', 'center']}>
          {description}
        </Text>
      </Flex>
    </ValuePropositionWrapper>
  );
};

interface CrossSellListProps {
  title: string;
  valuePropositions: ValuePropositionWithLinkProps[];
}
const CrossSellList: FC<CrossSellListProps> = ({ title, valuePropositions = [] }: CrossSellListProps) => (
  <>
    <Heading.h2
      data-testid="cross-sell-title"
      color="greys.charcoal"
      fontSize="lg"
      mb={6}
      textAlign="center"
      fontWeight="normal"
      aria-label={title ?? 'Discover great deals with Qantas'}
    >
      {title}
    </Heading.h2>
    <Grid>
      {valuePropositions.map((item) => (
        <ValueProposition key={item.title} {...item} />
      ))}
    </Grid>
  </>
);

export default CrossSellList;
