export const addGclidToUrl = (pathname, gclid) => {
  const baseUrl = new URL(pathname, 'https://dummy.com');
  const params = new URLSearchParams(baseUrl.search);
  params.append('gclid', gclid);

  return baseUrl.pathname + '?' + decodeURIComponent(params.toString());
};

export const getLinkUrl = (link, gclid) => {
  const { url, fullName } = link;

  const baseSearchUrl = new URL('/search/list', 'https://dummy.com');
  const params = new URLSearchParams(baseSearchUrl.search);
  params.append('location', fullName);

  if (gclid) {
    params.append('gclid', gclid);
  }

  const urlWithLocation = baseSearchUrl.pathname + '?' + decodeURIComponent(params.toString());
  const linkUrl = gclid ? addGclidToUrl(url, gclid) : url;

  return url ? linkUrl : urlWithLocation;
};
